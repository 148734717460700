




































import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { CircuitsAction, CircuitsActionType, CircuitsState } from '@/store/modules/circuits/types';
import { AppState } from '@/store/modules/app/types';
import { ScenariosGetter, ScenariosGetterType } from '@/store/modules/scenarios/types';
import { GroupsGetter, GroupsGetterType, GroupsState } from '@/store/modules/groups/types';
import { Models } from '@mtap-smartcity/api';
import { sort } from '@/utils/sort';
import {
  UserGetter, UserGetterType
} from '@/store/modules/admin/types';

const admin = namespace('admin');
const groups = namespace('groups');
const circuits = namespace('circuits');
const scenarios = namespace('scenarios');
const app = namespace('app');

@Component
export default class AssignTableCircuits extends Vue {
  @app.State
  userActionStatus!: AppState['userActionStatus'];

  @circuits.State
  circuits!: CircuitsState['circuits']

  @groups.State
  selectedGroupID!: GroupsState['selectedGroupID']

  @groups.State
  selectedGroupUuid!: GroupsState['selectedGroupUuid']

  @groups.Getter(GroupsGetter.GetGroup)
  getGroup!: GroupsGetterType['GET_GROUP'];

  @scenarios.Getter(ScenariosGetter.GetScenario)
  getScenario!: ScenariosGetterType['GET_SCENARIO'];

  @circuits.Action(CircuitsAction.AddCircuitToGroup)
  addCircuitToGroup!: CircuitsActionType['ADD_CIRCUIT_TO_GROUP'];

  @admin.Getter(UserGetter.GetPermissions)
  GetPermissions!: UserGetterType['GET_PERMISSIONS'];

  get sortedCircuits() {
    let result = this.circuits.sort((a, b) => sort(a.name, b.name));
    if (this.selectedGroupID) {
      result = result.sort((a, b) => {
        if (a.group_id === this.selectedGroupID && b.group_id !== this.selectedGroupID) {
          return -1;
        }
        return 1;
      });
    }
    return result;
  }

  get permissionCheckWrite() {
    const getPermissions = this.GetPermissions.find((item) => item.type === 'circuits');
    return getPermissions.write;
  }

  groupName(circuit: Models.Circuits.Model) {
    const { group_id } = circuit;
    if (!group_id) {
      return '';
    }
    const { name } = this.getGroup(group_id) ?? { name: '' };
    return name;
  }

  scenarioName(circuit: Models.Circuits.Model) {
    const { scenario_id } = circuit;
    if (!scenario_id) {
      return '';
    }
    const { name } = this.getScenario(scenario_id) ?? { name: '' };
    return name;
  }

  belongsToSelectedGroup(circuit: Models.Circuits.Model) {
    return circuit.group_id ? circuit.group_id === this.selectedGroupUuid : false;
  }

  addToGroup(circuit: Models.Circuits.Model, switchLoader: () => void) {
    switchLoader();
    const groupId = this.belongsToSelectedGroup(circuit) ? null : this.selectedGroupUuid;
    this.addCircuitToGroup({
      uuid: circuit.uuid as string,
      groupId,
    }).finally(() => {
      switchLoader();
    });
  }
}
